.requests{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-repeat: no-repeat;
    background-color: white;
    background-size: cover;
}

.request_body{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 600px;
    margin-top: 4em;
}

.request_body>h3{
    margin-top: 2em;
    width: 100%;
    font-size: 40px;
    text-align: center;
}

.request_body>p{
    width: 100%;
    text-align: center;
}

.inp{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.inp>select{
    padding: 15px;
    border-radius: 5px;
    outline-width: 0;
    margin-top: .5em;
}

.inp>input{
    padding: 15px;
    border-radius: 5px;
    outline-width: 0;
    font-size: 16px;
    zoom: 1;
    margin-top: .5em;
    border: 1px solid grey;
}

.inp>textarea{
    padding: 15px;
    height: 160px;
    resize: none;
    border-radius: 5px;
    outline-width: 0;
    font-size: 16px;
    zoom: 1;
    margin-top: .5em; 
}

.inp>p{
    font-size: 13px;
}

.request_body>button{
    width: 90%;
    padding: 10px;
    background: rgb(17,12,173);
    background: linear-gradient(90deg, rgba(17,12,173,1) 0%, rgba(28,28,180,1) 35%, rgba(0,112,255,1) 100%);
    font-weight: 800;
    border: none;
    color: white;
    border-radius: 5px;
}

.request_body>p{
    margin: 2em 0;
    width: 95%;
}