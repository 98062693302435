.main_head{
    width: 100%;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    background-color: white;
    z-index: 10000;
    color: black;
    border-bottom: 2px solid grey;
}

.header::before{
    content: '';
    background: rgb(17,12,173);
    background: linear-gradient(90deg, rgba(17,12,173,1) 0%, rgba(28,28,180,1) 35%, rgba(0,112,255,1) 100%);
    position: absolute;
    left: -1em;
    height: 10vh;
    width: 40%;
    transform: skew( -18deg);
}

.header{
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15px;
    top: 0;
    z-index: 1000;
    font-size: 12px;
    color: black;
    height: 10vh;
}

.glass::before{
    content: '';
    background: white;
    position: absolute;
    display: flex;
    left: -1em;
    height: 10vh;
    width: 40%;
    transform: skew( 18deg);
    box-shadow: 8px -8px 10px 0px rgba(0, 0, 0, 0.3);
}

.glass{
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15px;
    top: 0;
    z-index: 1000;
    font-size: 12px;
    color: black;
    height: 15vh;
}

.links{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4em;
    color: black;
}

.link{
    margin-right: 15px;
    padding: 5px;
    text-decoration: none;
    font-weight: 600;
    font-size: 10px;
    border-right: 2px solid grey;
    color: rgba(0, 0, 0, 0.548);
}

.request{
    display: flex;
    color: white;
    text-decoration: none;
    width: fit-content;
    padding: 10px;
    background: rgb(17,12,173);
    background: linear-gradient(90deg, rgba(17,12,173,1) 0%, rgba(28,28,180,1) 35%, rgba(0,112,255,1) 100%);
    font-weight: 800;
    border-radius: 5px;
    box-shadow: 2px 4px 14px -3px grey;
    align-items: center;
    font-size: 10px;
    cursor: pointer;
    margin-top: 1em;
}

.work_time{
    display: flex;
    font-weight: 800;
    font-size: 10px;
    color: white;
    align-items: center;
    display: block;
}

.navbar{
    display: none;
}

.logo_bod{
    display: flex;
    align-items: center;
    color: white;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    z-index: 1000;
    justify-content: center;
}

.logo_bod>img{
    width: 5em;
    border-radius: 50%;
    margin: 10px;
}

.navs>p{
    font-size: 13px;
    color: black;
}

.navs{
    color: black;
}

.extrhd{
    width: 100%;
    padding: 1em 10px;
}

.extrawrt{
    width: 100%;
    padding: 1em 10px;
}

.extrawrt>small{
    font-size: 12px;
    cursor: pointer;
}

.extrawrt>small{
    margin: 0 5px;
}

.extrawrt>small:hover{
    color: blue;
}

.tophd1{
    border-bottom: .5px solid grey;
    background-color: white;
}






@media only screen and (max-width: 600px){
    .header{
        display: none;
    }
    
    .glass::before{
        content: '';
        background: white;
        position: absolute;
        display: flex;
        left: -1em;
        height: 10vh;
        width: 70%;
        transform: skew( 18deg);
        box-shadow: 8px -8px 10px 0px rgba(0, 0, 0, 0.3);
    }

    .extrawrt>small{
        font-size: 10px;
    }

    .navbar{
        display: block;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        color: black;
        height: 10vh;
        z-index: 1000;
        transition: 3s;
    }

    .logo_bod{
        display: flex;
        align-items: center;
        font-size: 12px;
        color: white;
        z-index: 1000;
    }
    
    .logo_bod>img{
        width: 5em;
        margin: 10px;
    }

    .navbar::before{
        content: '';
        /* background-color: blue; */
        position: absolute;
        left: -1em;
        height: 10vh;
        width: 50%;
        transform: skew( -18deg);
        background: rgb(17,12,173);
        background: linear-gradient(90deg, rgba(17,12,173,1) 0%, rgba(28,28,180,1) 35%, rgba(0,112,255,1) 100%);
    }
    
    .nav_bod{
        inset: 0 0 0 20%;
        background-color: white;
        z-index: 1000;
        padding: 2em;
        z-index: 100000;
        position: absolute;
        right: 0;
        top: 3.2em;
        height: fit-content;
        padding-bottom: 5em;
        box-shadow: 2px 4px 14px -3px #222;
        border-left: 2px solid blue;
    }


    .nav_bod::before{
        content: '';
        position: absolute;
        background-color: white;
        left: 1em;
        bottom: -5em;
        height: 5em;
        width: 50%;
        transform: skew( 23deg);
        border-left: 2px solid blue;
        border-bottom: 2px solid blue;
    }


    .nav_bod::after{
        content: '';
        background-color: white;
        position: absolute;
        right: 0;
        bottom: -4.8em;
        height: 5em;
        width: 50%;
        border-bottom: 2px solid blue;
    }

    .navs>p{
        margin-left: .5em;
    }

    .cancle{
        position: absolute;
        right: 1em;
        border-radius: 50%;
        box-shadow: 2px 4px 14px -3px grey;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .nav_links>.request{
        display: flex;
        color: black;
        text-decoration: none;
        width: 80%;
        padding: 10px;
        margin-left: 1em;
        background-color: white;
        color: black;
        font-weight: 800;
        border-radius: 5px;
        box-shadow: 2px 4px 14px -3px grey;
        align-items: center;
        font-size: 10px;
        cursor: pointer;
    }

    .navs{
        width: 80%;
        display: flex;
        margin: 10px;
        align-items: center;
        text-decoration: none;
        color: black;
        background-color: white;
        font-size: 10px;
        font-weight: 800;
        outline-width: 0;
        padding: 10px;
        border-radius: 5px;
        box-shadow: 2px 4px 14px -3px grey;
        
    }

    .nav_links{
        margin-top: 8em;
        width: 100%;

    }

    #avatar{
        height: 1.2em;
        width: 1.2em;
    }

    #cancel{
        height: 2em;
        width: 2em;
    }

    #menu{
        width: 2em;
        height: 2em;
    }
}