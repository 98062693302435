.about{
    display: flex;
    flex-direction: column;
}

.about_body{
    color: white;
}

.about_body>span{
    font-size: 12px;
}

.about_body>h1{
    font-size: 50px;
}

.abt>p,.abt>h1,.abt>span{
    z-index: 100;
}

.about_body{
    font-family: Arial, Helvetica, sans-serif;
}

.abt{ 
    width: 100%;
    height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: white;
    background-image: url('./img/abtbg.jpg');
    background-repeat: no-repeat;
    background-color: #cccccc;
    background-size: cover;
}

.sec_box{
    width: 100%;
    margin-top: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sec_box>img{
    width: 100%;
    max-width: 300px;
    height: 300px;
    margin: 10px;
    border-radius: 20px;
}

.sec_box_sub{
    width: 50%;
}

.trd_box>img{
    width: 100%;
    max-width: 300px;
    height: 300px;
    margin: 10px;
    border-radius: 20px;
}

.trd_box{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.trd_box2{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
}

.trd_box2>img{
    border-radius: 10px;
    margin-top: 2em;
    width: 100%;
    max-width: 300px;
}



.about_box{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.sec_box_sub>p{
    line-height: 27px;
}

.abt{
    padding-top: 4em;
    position: relative;
}

.abtshd{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #222222a9;
    top: 0;
}

.abt>span{
    line-height: 27px;
}

.trd_box2>img{
    border-radius: 50%;
    width: 6em;
    object-fit: cover;
    height: 6em;
}

.about_box>h1, .about_box>span, about_box>p{
    width: 95%;
    text-align: center;
    margin: 10px;
}



@media only screen and (max-width: 600px){
    .abtshd{
        display: none;
    }

    .track_field{
        height: 50vh;
        border-bottom-right-radius: 0;
        padding-top:.5em;
    }

    .track_field>h3{
        font-size: 15px;
    }

    .track_security>p{
        font-size: 12px;
        width:90%;
    }

    .total1>h3{
        font-size:15px;
    }
    .total2>h3{
        font-size:15px;
    }
    .abt{
        height:fit-content;
        padding-top: 5em;
        background-size: contain;
        background-color: white;
        color: black;
        margin-top: 7em;
    }

    .abt>span{
        width: 95%;
    }

    .sec_box{
        display: flex;
        flex-direction: column-reverse;
    }
    .trd_box{
        margin-top: 1em;
        display: flex;
        flex-direction: column;
    }
    .trd_box2{
        margin-top: 1em;
        display: flex;
        flex-direction: column;
    }

    .sec_box_sub{
        width: 90%;
    }

}