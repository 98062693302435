.contact{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-repeat: no-repeat;
    background-size: cover;
}

.contact_body{
    width: 100%;
    max-width: 700px;
    padding-top: 4em;
}

.contacthd{
    background-image: url('./img/abtbg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    position: relative;
}

.contacthd>h3{
    z-index: 100;
    margin-top: 4em;
}

.contact_body>h3{
    margin-top: 1em;
    width: 100%;
    text-align: center;
}

.comp{
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    flex-direction: column;
    margin: 20px;
}

.comp>p{
    font-size: 13px;
}

.comp>h3{
    font-size: 15px;
    font-weight: 400;
    margin-top: 1em;
}

.contact_body>input{
    width: 90%;
    margin:10px ;
    padding: 15px;
    outline-width: 0;
    border-radius: 5px;
    font-size: 16px;
    zoom: 1;
}

.contact_body>textarea{
    width: 90%;
    height: 180px;
    margin:10px ;
    padding: 10px;
    outline-width: 0;
    border-radius: 5px;
    border: 2px solid grey;
    resize: none;
    overflow: hidden;
    font-size: 16px;
    zoom: 1;
}

.contact_body>textarea::placeholder{
    font-family: Arial, Helvetica, sans-serif;
}

.contact_body>button{
    width: 180px;
    padding: 10px;
    font-weight: 800;
    border-radius: 5px;
    background: rgb(17,12,173);
    background: linear-gradient(90deg, rgba(17,12,173,1) 0%, rgba(28,28,180,1) 35%, rgba(0,112,255,1) 100%);
    color: white;
    border: none;
}


@media only screen and (max-width: 600px){
    .contacthd{
        width: 100%;
        background-size: cover;
        height: 15vh;
        margin-top: 6.5em;
    }

    .contacthd>h3{
        margin-top: 1em;
    }
}