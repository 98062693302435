*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.load{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loader{
  width: 10em;
  height: 10em;
}