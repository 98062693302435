.mab_bod{
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;    
    justify-content: center;
}

.map1{
    background-image: url('https://www.jctrans.net/luodi/images/mapp1.png');
    background-repeat: no-repeat;
    background-color: transparent;
    height: 250px;
    width: 250px;
    background-size: cover;
    margin: 10px;
    box-shadow: 2px 4px 14px -3px grey;
    border-radius: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.map1:hover{
    color: orange;
}
.map2:hover{
    color: orange;
}
.map3:hover{
    color: orange;
}
.map4:hover{
    color: orange;
}
.map5:hover{
    color: orange;
}
.map6:hover{
    color: orange;
}

.map2{
    background-image: url('https://www.jctrans.net/luodi/images/mapp2.png');
    background-repeat: no-repeat;
    background-color: transparent;
    height: 250px;
    width: 250px;
    background-size: cover;
    margin: 10px;
    box-shadow: 2px 4px 14px -3px grey;
    border-radius: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.map3{
    background-image: url('https://www.jctrans.net/luodi/images/mapp3.png');
    background-repeat: no-repeat;
    background-color: transparent;
    height: 250px;
    width: 250px;
    background-size: cover;
    margin: 10px;
    box-shadow: 2px 4px 14px -3px grey;
    border-radius: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.map4{
    background-image: url('https://www.jctrans.net/luodi/images/mapp4.png');
    background-repeat: no-repeat;
    background-color: transparent;
    height: 250px;
    width: 250px;    
    background-size: cover;
    margin: 10px;
    box-shadow: 2px 4px 14px -3px grey;
    border-radius: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.map5{
    background-image: url('https://www.jctrans.net/luodi/images/mapp5.png');
    background-repeat: no-repeat;
    background-color: transparent;
    height: 250px;
    width: 250px;
    background-size: cover;
    margin: 10px;
    box-shadow: 2px 4px 14px -3px grey;
    border-radius: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.map6{
    background-image: url('https://www.jctrans.net/luodi/images/mapp6.png');
    background-repeat: no-repeat;
    background-color: transparent;
    height: 250px;
    width: 250px;
    background-size: cover;
    margin: 10px;
    box-shadow: 2px 4px 14px -3px grey;
    border-radius: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

}


