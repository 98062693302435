.track{
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background-image: url('https://wallpapercave.com/dwp1x/wp10685835.jpg');
    background-repeat: no-repeat;
    background-color: aliceblue;
    background-size: cover;
}

.rec{
    position: absolute;
    left: 2em;
    top: 12em;
    outline-width: 0;
    z-index: 100000;
}

.track_bod{
    /* width: 100%; */
    border-radius: 20px;
    margin-top: 5em;
    max-width: 400px;
    height: 500px; 
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: 2px 4px 14px -3px grey;
    background-color: cadetblue;
}

.track_bod1{
    overflow-y: scroll;
    margin-top: 3em;
    padding: 3em;
    height: 90vh;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 1;
    background-color: transparent;
}

.track_bod1::-webkit-scrollbar{
    display: none;
}

.track_head{
    display: flex;
    justify-content: space-between;
}

.track_sub{
    margin: 10px;
    color: white;
}

.trach_subhead{
    margin-left: 3em;
    word-wrap: break-word;
}

.flip{
    display: flex;
    margin-top: 1em;
}

.flip{
    width: 100%;
    display: flex;
    margin-top: 2em;
    align-items: center;
    justify-content: center;
}

.flip2{
    width: 100%;
    display: flex;
    margin-top: 2em;
    align-items: center;
    justify-content: center;
    margin-bottom: 2em;
}

.flip_icon{
    display: flex;
    align-items: center;
    color: whitesmoke;
}

.flip_icon1{
    display: flex;
    align-items: center;
}

.flip_icon2{
    display: flex;
    align-items: center;
    color: purple;
}

.sail{
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.label{
    width: 90%;
    max-width: 210px;
    padding: 10px;
    background-color: whitesmoke ;
    margin-top: 10px;
    border-radius: 5px;
    color: cadetblue;
}

.track_sub>p{
    font-size: 12px;
}

.other_details{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.detail_box{
    margin: 10px;
    width: 320px;
    height: fit-content;
    background-color: rgba(95, 158, 160, 0.623);
    padding-top: 20px;
    border-radius: 20px;
    box-shadow: 2px 4px 14px -3px grey;
    color:white;
}

.detail_box>h3{
    padding: 10px;
    font-size: 15px;
    width: 100%;
    text-align: center;
}

.detail_box>div{
    background-color: rgba(245, 245, 245, 0.548);
    color: black;
    width: 100%;
    height: 160px;
    border-bottom-left-radius:20px ;
    border-bottom-right-radius: 20px;
    padding-left: 10px;
    font-size: 12px;
    font-weight: 700;
    word-wrap: break-word;
}

.detail_box>p>span{
    word-wrap: break-word;
    width: 100%;
}

.null{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    padding-top: 4em;
}








.table{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    background-color: rgba(95, 158, 160, 0.705);
    max-width:600px ;
    margin-bottom: 4em;
    color: white;
}

.table_head{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.table_head>h3{
    width: 25%;
    padding: 5px;
    color: white;
    font-size: 15px;
    border: 2px solid white;
}

.table_seg{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.table_seg>p{
    width: 25%;
    color: white;
    padding: 5px;
    border: 2px solid white;
    font-size: 12px;
    font-weight: 700;
    height: 4em;
}

.secu{
    width: 100%;
    max-width: 300px;
    object-fit: contain;
    margin: 10px;
}

.track_bod2{
    padding: 10px;
    width: 90%;
    height: 500px;
    background-color: rgba(95, 158, 160, 0.603);
}

.packageimg{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top:2em;
}

.packageimg>img{
    object-fit: contain;
    max-width: 500px;
    width: 100%;
    height: 400px;
    border-radius: 10px;
}

















.leaflet-container{
    height: 500px;
    width: 95%;
}






#icons{
    width: 1.5em;
    height: 1.5em;
    color: purple;
    cursor: pointer;
}

#iconc{
    width: 1.5em;
    height: 1.5em;
    color: whitesmoke;
    cursor: pointer;
}

#icon{
    width: 1.5em;
    height: 1.5em;
}




@media only screen and (max-width: 700px){
    .rec{
        top: 14em;
    }
    
    .track{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        background-image: url('https://wallpapercave.com/dwp1x/wp10685835.jpg');
        background-repeat: no-repeat;
        background-color: aliceblue;
        background-size: cover;
    }
    
    .other_details{
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .track_bod{
        width: 100%;
        border-radius: 20px;
        margin-top: 5em;
        height: 500px;
        display: flex;
        align-items: center;
        flex-direction: column;
        box-shadow: 2px 4px 14px -3px grey;
        background-color: cadetblue;
    }
    
    .track_bod1{
        margin-top: 3em;
        height: 87vh;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        flex: 1;
        background-color: transparent;
    }

    .detail_box{
        margin: 10px;
        width: 100%;
        height: fit-content;
        background-color: rgba(95, 158, 160, 0.623);
        border-radius: 20px;
        box-shadow: 2px 4px 14px -3px grey;
        color:white;
    }

    .track_head{
        width: 100%;
        margin-top: 4em;
    }


}