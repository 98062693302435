.ban_bod{
    width: 100%;
    height: 100vh;
    color: white;
    position: relative;
    display: flex;
    align-items: center;
}

.writes>h1{
    font-size: 45px;
    color: white;
}


.writes>span{
    width: fit-content;
    color: white;
    font-size: 20px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.writes{
    width: 50%;
}

.ban_bod2{
    margin-top: 4em;
    position: absolute;
    top:4em ;
    left: 60%;
    transform: translate(-50%, -50%);
    perspective: 1000px;
}

.ban{
    height: 350px;
    width: 350px;
    position: absolute;
    top: 10px;
    left: 10px;
    transform-style: preserve-3d;
    transform: rotateY(30deg);
    animation: animate 15s infinite;
}

@keyframes animate {
    0%{
        transform: rotateX(-85deg) rotateY(85deg);
    }
    25%{
        transform: rotateX(-85deg) rotateY(-85deg);
    }
    50%{
        transform: rotateX(85deg) rotateY(85deg);
    }
    75%{
        transform: rotateX(85deg) rotateY(-85deg);
    }
    100%{
        transform: rotateX(-85deg) rotateY(85deg);
    }
}



.img{
    background-image: url('https://yessenders.com/en/files/slider-2.977b767c.jpg');
    background-repeat: no-repeat;
    background-color: #cccccc;
    height: 350px;
    width: 350px;
    background-size: cover;
    text-align: center;
    padding: 100px 0px;
    box-sizing: border-box;
    position: absolute;
}

.img1{
    background-image: url('https://www.sgkglobal.com/wp-content/uploads/2020/07/nonhome-41369540.jpg');
    background-repeat: no-repeat;
    background-color: #cccccc;
    height: 350px;
    width: 350px;
    background-size: cover;
    text-align: center;
    padding: 100px 0px;
    box-sizing: border-box;
    position: absolute;
}

.img2{
    background-image: url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkHcQk4USQpIFguo54wNHO2s97ckwhmqF7nHpQ1KjUnXT8KUggw3OQb99QfjFBmy2yaKc&usqp=CAU');
    background-repeat: no-repeat;
    background-color: #cccccc;
    height: 350px;
    width: 350px;
    background-size: cover;
    text-align: center;
    padding: 100px 0px;
    box-sizing: border-box;
    position: absolute;
}

.img3{
    background-image: url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7sZuED1p_vjP7H-bY4ZfeAkpBeCIljt66z9Wwh6i6QUIwdg_FsvoIrmorKcsDOg36yLI&usqp=CAU');
    background-repeat: no-repeat;
    background-color: #cccccc;
    height: 350px;
    width: 350px;
    background-size: cover;
    text-align: center;
    padding: 100px 0px;
    box-sizing: border-box;
    position: absolute;
}

.img5{
    background-image: url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_hcQhKpo9TaiMxpIH2mxu6nBDyZkrNa2heQ&usqp=CAU');
    background-repeat: no-repeat;
    background-color: #cccccc;
    height: 350px;
    width: 350px;
    background-size: cover;
    text-align: center;
    padding: 100px 0px;
    box-sizing: border-box;
    position: absolute;
}

.img4{
    background-image: url('https://www.sgkglobal.com/wp-content/themes/sgkglobaluc/images/slider/nonhome-noid1.jpg');
    background-repeat: no-repeat;
    background-color: #cccccc;
    height: 350px;
    width: 350px;
    background-size: cover;
    text-align: center;
    padding: 100px 0px;
    box-sizing: border-box;
    position: absolute;
}



#front{
    transform: translateZ(175px);
}

#back{
    transform: translateZ(-175px);
}

#left{
    right: 175px;
    transform: rotateY(-90deg);
}

#right{
    left: 175px;
    transform: rotateY(90deg);
}

#top{
    bottom: 175px;
    transform: rotateX(90deg);
}

#bottom{
    top: 175px;
    transform: rotateX(-90deg);
}

.img-ban{
    z-index: 100;
    width: 100%;
    align-items: center;
}

.img_ban>h3{
    width: 40%;
    display: flex;
    text-align: center;
    color: white;
    align-items: center;
    font-size: 15px;
}

.vid{
    width: 100%;
    object-fit: cover;
    margin-top: -6em;
}

.gif{
    display: none;
}

.banvid{
    position: relative;
}
















@media only screen and (max-width: 700px){
    .vid{
        display: none;
    }
    .gif{
        display: flex;
        width: 100%;
        height:250px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 6em;
    }

    .gif>img{
        width: 100%;
        height: 250;
    }

    .banvid{
        margin-top: .5em;
    }

    .ban_bod{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .ban_bod2{
        margin-top: 0;
        position: absolute;
        top:8em ;
        left: 4em;
        transform: translate(-50%, -50%);
        perspective: 1000px;
    }

    .writes{
        margin-top: 27em;
        width: 100%;
    }

    .writes>span{
        font-size: 15px;
        margin-bottom: 2em;
    }
    .writes1>span{
        font-size: 15px;
    }

    .ban{
        height: 200px;
        width: 200px;
        position: absolute;
        top: 10px;
        left: 10px;
        /* transform: translate(-90deg); */
        transform-style: preserve-3d;
        transform: rotateY(30deg);
        animation: animate 15s infinite;
        z-index: 100;
    }

    .img{
        width: 200px;
        height: 200px;
    }
    .img1{
        width: 200px;
        font-size: 10px;
        height: 200px;
    }
    .img2{
        width: 200px;
        height: 200px;
    }
    .img3{
        width: 200px;
        height: 200px;
    }
    .img4{
        width: 200px;
        height: 200px;
    }
    .img5{
        width: 200px;
        height: 200px;
    }

    #front{
        transform: translateZ(120px);
    }
    
    #back{
        transform: translateZ(-120px);
    }
    
    #left{
        right: 120px;
        transform: rotateY(-90deg);
    }
    
    #right{
        left: 120px;
        transform: rotateY(90deg);
    }
    
    #top{
        bottom: 120px;
        transform: rotateX(90deg);
    }
    
    #bottom{
        top: 120px;
        transform: rotateX(-90deg);
    }
}